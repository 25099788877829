<template>
	<div>
    <div class="w-auto" v-if="loading">
      <clip-loader :loading="loading" color="primary"></clip-loader>
    </div>
    <div :id="screenClass" class="p-4" v-else>
      <div style="">
        <div style="position: absolute">
          <img
            style="height: 80px"
            src="@/assets/images/pages/logo-simple.jpg"
            alt="aab"
            class="mx-auto"
          />
        </div>
        <div style="padding-top: 40px; padding-bottom: 30px;">
          <div class="uk-text-center">
            <strong style="font-size: 40px">
              Evolution du contrat
              <span class="aab-contract-evolution-title-color">
                {{ dataProject.refContrat }}
              </span>
            </strong>
            <p class="pb-2">
              Veuillez noter que l'évolution du contrat est basée sur des données
              historiques. Les mouvements sont, par conséquent, affichés en fonction
              de leur date d'allocation.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div @click="goToScreenshot"
          class="uk-text-right mr-2 mb-2"
          style="cursor: pointer" title="Télécharger">
          <feather-icon
            class="text-dark"
            icon="PrinterIcon"
            svgClasses="w-6 h-6"
          ></feather-icon>
        </div>
        <vue-apex-charts
          type="area"
          height="500"
          ref="chart"
          :options="lineChartSimple.chartOptions"
          :series="lineChartSimple.series"
        ></vue-apex-charts>
      </div>
      <div class="mt-8">
        <span>Suivez-nous sur les réseaux sociaux  
          <a href="https://www.facebook.com/aabvieofficielle/" target="_blank">
              <feather-icon style="padding-left: 4px;padding-right: 3px;" svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="FacebookIcon"></feather-icon>
          </a>
          <a href="https://www.linkedin.com/in/atlantique-assurances-benin-vie-2507891a2" target="_blank">
              <feather-icon svgClasses="h-7 w-7 cursor-pointer bg-primary p-1 text-white rounded" class="mr-2" icon="LinkedinIcon"></feather-icon>
          </a>©️ {{ new Date().getFullYear() }} Atlantique Assurances Bénin Vie - Tous droits réservés. 
          <router-link :to="{name: 'PrivacyAndSecurity'}" target="_blank">
              Politiques juridiques, confidentialité et de sécurité
          </router-link>
          <a href="https://bilansanteinternational.com/e-bsi/" target="_blank"> - Powered by E-BSI</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mixin } from "@/mixins/mixin";
import moment from "@/helpers/moment";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueApexCharts from 'vue-apexcharts'
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

export default {
  name: "aab-vie-suscribe-general-informations",
  components: {
    ClipLoader,
    VueApexCharts,
  },
  props: {
    crrModuleName: String,
    item: String,
    dataProject: Object,
  },
  data() {
    return {
      loading: false,
      screenClass: "",
      propositionChoose: {},
      startDateContributions: "",
      endDateContributions: "",
    };
  },
  mounted() {
    this.initDataLocal()
  },
  computed: {
    ...mapGetters("general", ["rowsTable"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("projetContrat", ["allProjetContrats"]),
    lineChartSimple() {
      let seriesData = []

      for (let index = 0; index < this.dataProject.quittances.length; index++) {
        seriesData.push([
          new Date(this.dataProject.quittances[index].startDateContributions).getTime(),
          parseInt(this.onCumulTotal(index + 1)),
        ]);
      }

      return {
        chartOptions: {
          chart: {
            id: "Evolution du contrat " + this.dataProject.refContrat,
            type: "area",
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          annotations: {
            yaxis: [],
            xaxis: [],
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          xaxis: {
            title: {
              text: 'Années de souscription',
              offsetY: 14,
              style: {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
              },
            },
            type: 'datetime',
          },
          yaxis: {
            title: {
              text: "Cumul cotisations nettes soldées (F CFA)",
              style: {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yyyy",
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 100],
            },
          },
        },
        series: [
          {
            name: "Cumul cotisations nettes soldées (F CFA)",
            data: seriesData
          },
        ],
      }
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    goToScreenshot() {
      let crrName = "Evolution du contrat " + this.dataProject.refContrat
      htmlToImage.toPng(document.getElementById(this.screenClass))
        .then(function (dataUrl) {

          var FileSaver = require("file-saver");
          FileSaver.saveAs(
            dataUrl,
            crrName
          )
        });
    },
    initDataLocal() {
      this.loading = true
      if (this.item) { // ID of project
        this.propositionChoose = this.dataProject.quote.aboutProposition.data.find(
          (el) => el.id == this.dataProject.quote.aboutProposition.selectedId
        )
        this.startDateContributions = mixin.methods
          .formatDate(this.dataProject.quittances[0].startDateContributions)

        this.endDateContributions = mixin.methods
          .formatDate(
            this.dataProject.quittances[this.dataProject.quittances.length - 1]
              .endDateContributions
          )

        this.screenClass = 'aab-chart-screen-' + this.dataProject.refContrat
        this.loading = false
      }
    },
    onCumulTotal(nbrQ) {
      let total = 0;

      if (nbrQ) {
        for (let index = 0; index < nbrQ; index++) {
          if(this.dataProject.quittances[index].paymentStatus == 'payOff') {
            total += parseInt(this.dataProject.quittances[index].cotisationTTC);
          }
        }
        total = parseInt(total) - this.propositionChoose.accessoir;
      }

      return total;
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    onFormatAmount(amount) {
      if (amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return 0;
      }
    },
  },
  watch: {
    item() {
      this.initDataLocal()
    },
  },
};
</script>
<style>
.aab-contract-evolution-title-color {
  color: rgba(var(--vs-primary), 1);
}
</style>
